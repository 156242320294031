import { ConfigProvider } from 'antd';

interface LayoutThemeProps {
  children: React.ReactNode;
}

const LayoutTheme = ({ children }: LayoutThemeProps) => {
  return (
    <ConfigProvider
      form={{
        requiredMark: false,
      }}
      theme={{
        token: {
          colorPrimary: '#1890ff',
        },
        components: {
          Input: {
            borderRadiusLG: 2,
            borderRadiusSM: 2,
            borderRadiusXS: 2,
            borderRadius: 2,
          },
          InputNumber: {
            borderRadiusLG: 2,
            borderRadiusSM: 2,
            borderRadiusXS: 2,
            borderRadius: 2,
          },
          Select: {
            borderRadiusLG: 2,
            borderRadiusSM: 2,
            borderRadiusXS: 2,
            borderRadius: 2,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default LayoutTheme;
