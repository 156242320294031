import styled from '@emotion/styled';
import { Button, ButtonProps } from 'antd';

const SecondaryButton = (props: ButtonProps) => {
  return <StyledButton type="default" shape="round" size="large" htmlType="button" {...props} />;
};

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 335px;
  display: block;
  margin: 0 auto;
  background-color: #fff;
`;

export default SecondaryButton;
