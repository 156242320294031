import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
      throwOnError: true,
    },
    mutations: {
      throwOnError: error => {
        // For mutations use error boudnary only for error 401 - invalid token (session expired).
        // Otherwise each mutation should have its own error handler in a form of notification.
        const err = error as AxiosError;
        return err?.response?.status === 401 ? true : false;
      },
    },
  },
});

export default queryClient;
