import { CountryPhoneInputValue } from 'antd-country-phone-input';
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';

export const parsePhone = (phoneString?: string): CountryPhoneInputValue => {
  const defaultValue = { short: 'US' };

  if (!phoneString) return defaultValue;
  try {
    const { country, countryCallingCode, nationalNumber } = parsePhoneNumber(
      phoneString
    ) as PhoneNumber;

    return {
      phone: nationalNumber,
      code: parseInt(countryCallingCode),
      short: country,
    };
  } catch (error) {
    return defaultValue;
  }
};

// Does the opposite than a function above.
export const phoneToString = (phoneInput?: CountryPhoneInputValue): string => {
  if (!phoneInput || !phoneInput.phone) return '';
  const { phone, code } = phoneInput;
  return `+${code}${phone}`;
};

export const formatPhoneCode = (phoneInput?: CountryPhoneInputValue): string => {
  if (!phoneInput || !phoneInput.code) return '+1';
  const { code } = phoneInput;
  return `+${code}`;
};
