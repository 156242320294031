import { notification } from 'antd';
import { AxiosError } from 'axios';
import { TFunction } from 'react-i18next';
import { AnyObject } from 'backendTypes';

export default function handlePhoneError(error: AxiosError, t: TFunction) {
  let hasPhoneError = false;
  if (error.response?.data) {
    const phoneErrorFields = Object.keys(error.response.data as AnyObject).map(fieldName => {
      switch (fieldName) {
        case 'phoneCell':
          return t('profile.mobilePhoneNumber');
        case 'workPhone':
          return t('profile.workPhoneNumber');
        default:
          return undefined;
      }
    });
    const translation = t('profile.invalidPhone');
    phoneErrorFields.forEach(field => {
      if (field) {
        notification.error({ message: translation.replace('#fieldName', field) });
        hasPhoneError = true;
      }
    });
  }
  if (!hasPhoneError) {
    notification.error({ message: t('auth.couldNotSaveUser') });
  }
}
