import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Divider, Modal, Typography } from 'antd';
import AuthButton from 'components/AuthButton';
import SecondaryButton from './SecondaryButton';

const { Title, Paragraph } = Typography;

export interface PopupModalProps {
  icon?: React.ComponentType;
  title: React.ReactNode;
  primaryDescription?: React.ReactNode;
  primaryButtonLabel?: React.ReactNode;
  onPrimaryButton?: () => void;
  description?: React.ReactNode;
  buttonLabel: React.ReactNode;
  onButton?: () => void;
}

const PopupModal = ({
  icon: Icon,
  title,
  primaryDescription,
  primaryButtonLabel,
  onPrimaryButton,
  description,
  buttonLabel,
  onButton,
}: PopupModalProps) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [Icon, title, description, buttonLabel, onButton]);

  return (
    <StyledModal centered open={isOpen} footer={null} closable={false} width={400}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      <StyledTitle level={3}>{title}</StyledTitle>
      {primaryDescription && <Paragraph>{primaryDescription}</Paragraph>}
      {primaryButtonLabel && (
        <AuthButton
          onClick={() => {
            onPrimaryButton && onPrimaryButton();
            setIsOpen(false);
          }}
        >
          {primaryButtonLabel}
        </AuthButton>
      )}
      {(primaryDescription || primaryButtonLabel) && <Divider />}
      {description && <Paragraph>{description}</Paragraph>}
      <SecondaryButton
        onClick={() => {
          onButton && onButton();
          setIsOpen(false);
        }}
      >
        {buttonLabel}
      </SecondaryButton>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  text-align: center !important;
`;

const IconWrapper = styled.div`
  margin: 16px auto;
`;

const StyledTitle = styled(Title)`
  font-weight: 300 !important;
`;

export default PopupModal;
