import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { Rewards } from 'backendTypes';

class RewardsService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getRewards(): Promise<Rewards> {
    const { data } = await restClient('/api/profile/rewards/', { method: 'GET' });
    return data;
  }

  async getRewardsForUser(eligibilityId: string): Promise<Rewards> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/rewards/`, {
      method: 'GET',
    });
    return data;
  }

  async redeemReward(rewardId: number): Promise<void> {
    const { data } = await restClient(`/api/profile/rewards/redeem/`, {
      method: 'POST',
      data: { rewardId },
    });
    return data;
  }

  async redeemRewardForUser(rewardId: number, eligibilityId: string): Promise<void> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/rewards/redeem/`, {
      method: 'POST',
      data: { rewardId },
    });
    return data;
  }
}

export { RewardsService };
