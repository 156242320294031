import useAuth from 'api/authProvider';
import { Navigate, useLocation } from 'react-router-dom';
import AppLoader from 'components/AppLoader';

interface RequireAuthProps {
  children: JSX.Element;
}

function RequireAuth({ children }: RequireAuthProps) {
  const { isLoggedIn, isLoading, hasAssessmentCompleted } = useAuth();
  const location = useLocation();
  const isAssessmentPage = location.pathname === '/assessment';

  return isLoggedIn ? (
    hasAssessmentCompleted ? (
      children
    ) : isAssessmentPage ? (
      children
    ) : (
      <Navigate to={'/assessment'} />
    )
  ) : isLoading ? (
    <AppLoader />
  ) : (
    <Navigate to={'/login'} />
  );
}

export default RequireAuth;
