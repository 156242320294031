import Resizer from 'react-image-file-resizer';

export default function resizeImage(file: File) {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300, // Maximum width
      300, // Maximum height
      'JPEG', // Format
      80, // Quality 1-100
      0, // Rotation
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });
}
