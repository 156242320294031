import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Link } = Typography;

const PolicyPopupDescription = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('auth.WeUpdatedOur')}{' '}
      <Link href="https://www.uspm.com/terms-of-use/" target="_blank">
        {t('auth.termsConditionsOf')}
      </Link>{' '}
      {t('auth.and')}{' '}
      <Link href="https://www.uspm.com/privacy-policy/" target="_blank">
        {t('auth.privacyPolicy')}
      </Link>
      . {t('auth.pleaseReviewAndClick')}
    </>
  );
};

export default PolicyPopupDescription;
