import { Suspense, lazy } from 'react';
import EnhancedRoute from 'EnhancedRoute';
import RequireAuth from 'RequireAuth';
import { Route, Routes } from 'react-router-dom';
import AppLoader from 'components/AppLoader';

const AccountConfirmationPage = lazy(() => import('pages/AccountConfirmationPage'));
const AssessmentPage = lazy(() => import('pages/AssessmentPage'));
const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'));
const CurrentUserPage = lazy(() => import('pages/CurrentUserPage'));
const DevicesPage = lazy(() => import('pages/DevicesPage'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const MyJourneyPage = lazy(() => import('pages/MyJourneyPage'));
const MyJourneyPursuitDetailsPage = lazy(() => import('pages/MyJourneyPursuitDetailsPage'));
const OneTimePasswordPage = lazy(() => import('pages/OneTimePasswordPage'));
const RegistrationPage = lazy(() => import('pages/RegistrationPage'));
const RewardsPage = lazy(() => import('pages/RewardsPage'));
const RewardsRedeemedPage = lazy(() => import('pages/RewardsRedeemedPage'));
const ScaleInsightsPage = lazy(() => import('pages/ScaleInsightsPage'));
const ScaleOrderPage = lazy(() => import('pages/ScaleOrderPage'));
const UserByIdPage = lazy(() => import('pages/UserByIdPage'));
const LayoutAuth = lazy(() => import('components/Layout/LayoutAuth'));
const LayoutDashboard = lazy(() => import('components/Layout/LayoutDashboard'));
const LayoutAnonymous = lazy(() => import('components/Layout/LayoutAnonymous'));

function App() {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/login" element={<EnhancedRoute layout={LayoutAuth} content={LoginPage} />} />
        <Route
          path="/otp"
          element={<EnhancedRoute layout={LayoutAuth} content={OneTimePasswordPage} />}
        />
        <Route
          path="/forgot-password"
          element={<EnhancedRoute layout={LayoutAuth} content={ForgotPasswordPage} />}
        />
        <Route
          path="/registration"
          element={<EnhancedRoute layout={LayoutAuth} content={RegistrationPage} />}
        />
        <Route
          path="/create-account"
          element={<EnhancedRoute layout={LayoutAuth} content={CreateAccountPage} />}
        />
        <Route
          path="/account-confirmation"
          element={<EnhancedRoute layout={LayoutAuth} content={AccountConfirmationPage} />}
        />
        <Route
          // path="/"
          index
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={CurrentUserPage} />
            </RequireAuth>
          }
        />
        <Route
          path="assessment"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={AssessmentPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/profile/:id"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={UserByIdPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/rewards"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={RewardsPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/rewards/:id"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={RewardsPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/rewards-redeemed"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={RewardsRedeemedPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/rewards-redeemed/:id"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={RewardsRedeemedPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/devices"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={DevicesPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/my-journey"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={MyJourneyPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/my-journey/:id"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={MyJourneyPursuitDetailsPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/scale-insights"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={ScaleInsightsPage} />
            </RequireAuth>
          }
        />
        <Route
          path="/scale-order"
          element={
            <RequireAuth>
              <EnhancedRoute layout={LayoutDashboard} content={ScaleOrderPage} />
            </RequireAuth>
          }
        />

        <Route path="*" element={<EnhancedRoute layout={LayoutAnonymous} content={ErrorPage} />} />
      </Routes>
    </Suspense>
  );
}

export default App;
