import restClient from 'api/restClient';
import {
  AssessmentService,
  AuthService,
  BiometricService,
  DevicesService,
  GraphsService,
  IconService,
  PursuitsService,
  RegistrationService,
  RewardsService,
  ScaleService,
  UserService,
} from 'api/services';

class Backend {
  auth: AuthService;
  user: UserService;
  biometric: BiometricService;
  assessment: AssessmentService;
  registration: RegistrationService;
  rewards: RewardsService;
  icon: IconService;
  devices: DevicesService;
  pursuits: PursuitsService;
  scale: ScaleService;
  graphs: GraphsService;

  constructor() {
    this.auth = new AuthService(restClient);
    this.user = new UserService(restClient);
    this.biometric = new BiometricService(restClient);
    this.assessment = new AssessmentService(restClient);
    this.registration = new RegistrationService(restClient);
    this.rewards = new RewardsService(restClient);
    this.icon = new IconService(restClient);
    this.devices = new DevicesService(restClient);
    this.pursuits = new PursuitsService(restClient);
    this.scale = new ScaleService(restClient);
    this.graphs = new GraphsService(restClient);
  }
}

export const backend = new Backend();
