import { BiometricData, BiometricDataKey, BiometricInputProps, Measurement } from 'backendTypes';

export default function prepareBiometricPostRequests(
  values: BiometricInputProps,
  initialValues: BiometricData,
  eligibilityId: string,
  measurement: Measurement
) {
  const filteredValuesKeys = Object.keys(values) as [BiometricDataKey];

  const filteredValues = filteredValuesKeys.reduce(
    (acc: BiometricInputProps, key: BiometricDataKey) => {
      if (key === 'weight' || key === 'waistline') {
        if (
          values[key].number !== initialValues?.[key]?.[measurement]?.value &&
          values[key].number !== '-'
        ) {
          acc[key] = values[key];
        }
      } else if (
        // @ts-ignore
        values[key].number !== initialValues?.[key]?.value ||
        // @ts-ignore
        values[key].unit !== initialValues?.[key]?.unit
      ) {
        acc[key] = values[key];
      }

      return acc;
    },
    {}
  );

  return Object.entries(filteredValues).map(item => {
    const [key, value] = item as [BiometricDataKey, { unit: string; number: number }];

    let unitId = 0;
    let measureTypeId = 0;

    switch (key) {
      case 'weight':
        measurement === 'imperial' ? (unitId = 1) : (unitId = 2);
        measureTypeId = 3;
        break;
      case 'waistline':
        measurement === 'imperial' ? (unitId = 14) : (unitId = 15);
        measureTypeId = 12;
        break;

      case 'systolic':
        unitId = 8;
        measureTypeId = 1;
        break;

      case 'diastolic':
        unitId = 8;
        measureTypeId = 2;
        break;

      case 'cholesterol':
        value.unit === 'mmol/L' ? (unitId = 18) : (unitId = 17);
        measureTypeId = 7;
        break;

      case 'hdl':
        value.unit === 'mmol/L' ? (unitId = 18) : (unitId = 17);
        measureTypeId = 10;
        break;

      case 'ldl':
        value.unit === 'mmol/L' ? (unitId = 18) : (unitId = 17);
        measureTypeId = 11;
        break;

      case 'triglycerides':
        value.unit === 'mmol/L' ? (unitId = 18) : (unitId = 17);
        measureTypeId = 9;
        break;

      case 'bloodsugar':
        value.unit === 'mmol/L' ? (unitId = 18) : (unitId = 17);
        measureTypeId = 8;
        break;

      case 'a1c':
        unitId = 19;
        measureTypeId = 15;
        break;

      default:
        break;
    }

    return {
      unitId,
      measureTypeId,
      eligibilityId,
      value: value.number,
      resourcetype: 'NumericMeasure',
    };
  });
}
