import styled from '@emotion/styled';
import { Button, ButtonProps } from 'antd';

const AuthButton = (props: ButtonProps) => {
  return <StyledButton type="primary" shape="round" size="large" htmlType="submit" {...props} />;
};

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 335px;
  display: block;
  margin: 0 auto;
  background-color: ${props => (props.type === 'primary' ? 'var(--blue-primary)' : 'undefined')};
`;

export default AuthButton;
