import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { backend } from 'api/backend';
import queryClient from 'api/queryClient';
import { AxiosError } from 'axios';
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import handlePhoneError from 'utils/handlePhoneError';
import usePopup from 'utils/usePopup';
import PolicyPopupDescription from 'components/PolicyPopupDescription';
import { LoginData, OTPLoginData, Pursuit, Pursuits, User } from 'backendTypes';
import { NewTerms } from 'assets';

const initialtUser = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  hasUserAccount: false,
};

interface AuthContextType {
  isLoading: boolean;
  isError: boolean;
  isLoggedIn: boolean;
  currentUser: User;
  hasAssessmentCompleted: boolean;
  refetchCurrentUser: () => void;
  currentUserIsBeingRefetched: boolean;
  login: (creds: LoginData) => void;
  loginOtp: (creds: OTPLoginData) => void;
  editCurrentUser: (userData: User) => void;
  editCurrentUserIsLoading: boolean;
  isLoggingIn: boolean;
  isLoggingInOtp: boolean;
  logout: () => void;
  pursuits?: Pursuits | null;
  activePursuit?: Pursuit | null;
  hasActivePursuit: boolean;
  refetchPursuits: () => void;
  refetchActivePursuit: () => void;
  pursuitIsFetching: boolean;
  activePursuitIsFetching: boolean;
  chooseNextPursuit: () => void;
}

const AuthContext = React.createContext<AuthContextType>({
  isLoading: true,
  isError: false,
  isLoggedIn: false,
  currentUser: initialtUser,
  hasAssessmentCompleted: false,
  refetchCurrentUser: () => {},
  currentUserIsBeingRefetched: false,
  login: creds => {},
  loginOtp: creds => {},
  editCurrentUser: userData => {},
  editCurrentUserIsLoading: false,
  isLoggingIn: false,
  isLoggingInOtp: false,
  logout: () => {},
  pursuits: null,
  activePursuit: null,
  hasActivePursuit: false,
  refetchPursuits: () => {},
  refetchActivePursuit: () => {},
  pursuitIsFetching: false,
  activePursuitIsFetching: false,
  chooseNextPursuit: () => {},
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<User>(initialtUser);
  const [shouldApplyPostLogin, setShouldApplyPostLogin] = React.useState(false);
  const [hasAssessmentCompleted, setHasAssessmentCompleted] = React.useState(false);
  const [hasActivePursuit, setHasActivePursuit] = React.useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const risePopup = usePopup();

  const postLogin = (isStaff?: boolean) => {
    notification.success({ message: t('auth.logInSuccess') });
    setShouldApplyPostLogin(false);
    const { source } = parse(search);
    // query-string supports also parsing arguments as arrays. Source param
    // shouldn't ever be an array, but user can type anything into address bar.
    // To avoid potencial risk let's not support arrays.
    const redirectTo = !source || Array.isArray(source) ? (isStaff ? '/' : '/my-journey') : source;
    navigate(redirectTo);
  };

  const agreePolicyMutation = useMutation({
    mutationFn: () => backend.user.agreePolicy(),
    onSuccess: () => {
      queryClient.setQueryData(['current-user'], (oldData: User | undefined) => ({
        ...initialtUser,
        ...oldData,
        hasAcceptedPolicy: true,
      }));
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: t('auth.agreementNotSaved'),
      });
    },
  });

  const {
    data: currentUserData,
    refetch: refetchCurrentUser,
    isRefetching: currentUserIsBeingRefetched,
    isError: currentUserIsError,
    isLoading: currentUserIsLoading,
  } = useQuery({
    queryKey: ['current-user'],
    queryFn: () => backend.user.getCurrentUser(),
  });

  useEffect(() => {
    setCurrentUser(currentUserData || initialtUser);

    if (currentUserData) {
      setIsLoggedIn(true);
      if (!currentUserData.hasAcceptedPolicy) {
        risePopup({
          icon: NewTerms,
          title: t('auth.pleaseReviewTerms'),
          primaryDescription: <PolicyPopupDescription />,
          primaryButtonLabel: t('auth.agreeContinue'),
          onPrimaryButton: () => agreePolicyMutation.mutate(),
          description: t('auth.agreeingIsRequired'),
          buttonLabel: t('auth.signOut'),
          onButton: () => logout(),
        });
      }
      if (shouldApplyPostLogin) {
        postLogin(currentUserData.isStaff);
      }
      currentUserData.isAssessmentCompleted || currentUserData.isStaff
        ? setHasAssessmentCompleted(true)
        : setHasAssessmentCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);

  useEffect(() => {
    if (!currentUserIsLoading) {
      setIsLoading(false);
    }
  }, [currentUserIsLoading]);

  const loginMutation = useMutation({
    mutationFn: (creds: LoginData) => backend.auth.login(creds),
    onSuccess: () => {
      setShouldApplyPostLogin(true);
      refetchCurrentUser();
    },
    onError: () => {
      notification.error({
        message: t('auth.couldNotLogin'),
      });
    },
    throwOnError: false, // Never use  global error boundary for login action.
  });

  const loginWithOneTimePasswordMutation = useMutation({
    mutationFn: (creds: OTPLoginData) => backend.auth.loginWithOneTimePassword(creds),
    onSuccess: () => {
      setShouldApplyPostLogin(true);
      refetchCurrentUser();
    },
    onError: () => {
      notification.error({
        message: t('auth.couldNotLoginOtp'),
      });
    },
    throwOnError: false, // Never use  global error boundary for login action.
  });

  const currentUserMutation = useMutation({
    mutationFn: (userData: User) => backend.user.editCurrentUser(userData),
    onSuccess: (newUser: User) => {
      queryClient.setQueryData(['current-user'], newUser);
      refetchCurrentUser();
    },
    onError: (error: AxiosError) => {
      handlePhoneError(error, t);
    },
  });

  const logout = () => {
    backend.auth.logout();
    setIsLoggedIn(false);
    queryClient.clear();
    notification.success({ message: t('auth.loggedOut') });
  };

  const isError = currentUserIsError || loginMutation.isError;

  const {
    data: pursuits,
    refetch: refetchPursuits,
    isFetching: pursuitIsFetching,
  } = useQuery({
    queryKey: ['listOfPursuits'],
    queryFn: () => backend.pursuits.getPursuits(),
    enabled: !currentUser.isStaff && isLoggedIn && hasAssessmentCompleted,
    refetchOnMount: false,
  });

  const {
    data: activePursuit,
    refetch: refetchActivePursuit,
    isFetching: activePursuitIsFetching,
  } = useQuery({
    queryKey: ['activePursuit'],
    queryFn: () => backend.pursuits.getActivePursuit(),
    enabled: !currentUser.isStaff && isLoggedIn && hasAssessmentCompleted,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (activePursuit) {
      if (activePursuit.id) {
        setHasActivePursuit(true);
      } else {
        setHasActivePursuit(false);
      }
    }
  }, [activePursuit]);

  const chooseNextPursuitMutation = useMutation({
    mutationFn: () => backend.pursuits.chooseNextPursuit(),
    onSuccess: () => {
      refetchActivePursuit();
      refetchPursuits();
    },
    onError: () => {
      notification.error({
        message: t('auth.couldNotSelectNewPursuit'),
      });
    },
  });

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isError,
        isLoggedIn,
        isLoggingIn: loginMutation.isPending,
        isLoggingInOtp: loginWithOneTimePasswordMutation.isPending,
        currentUser,
        hasAssessmentCompleted,
        refetchCurrentUser,
        currentUserIsBeingRefetched,
        login: loginMutation.mutate,
        loginOtp: loginWithOneTimePasswordMutation.mutate,
        editCurrentUser: currentUserMutation.mutate,
        editCurrentUserIsLoading: currentUserMutation.isPending,
        logout,
        pursuits,
        activePursuit,
        hasActivePursuit,
        refetchPursuits,
        refetchActivePursuit,
        pursuitIsFetching,
        activePursuitIsFetching,
        chooseNextPursuit: chooseNextPursuitMutation.mutate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);
export default useAuth;
