import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { LoginData, OTPLoginData } from 'backendTypes';

class AuthService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  public async login(credentials: LoginData): Promise<void> {
    delete restClient.defaults.headers.common.authorization;

    const { data } = await restClient('/api/login/', {
      method: 'POST',
      data: credentials,
    });
    const accessToken = data.token as string;
    window.localStorage.setItem('auth:accessToken', accessToken);
    restClient.defaults.headers.common.authorization = `Token ${accessToken}`;
  }

  public logout(): void {
    window.localStorage.removeItem('auth:accessToken');
    delete restClient.defaults.headers.common.authorization;
  }

  public async getOneTimePassword(login: string): Promise<string> {
    const { data } = await restClient('/api/otp/', {
      method: 'POST',
      data: { login },
    });
    return data.timestamp;
  }

  public async loginWithOneTimePassword(credentials: OTPLoginData): Promise<void> {
    delete restClient.defaults.headers.common.authorization;

    const { data } = await restClient('/api/otp/login/', {
      method: 'POST',
      data: credentials,
    });
    const accessToken = data.token as string;
    window.localStorage.setItem('auth:accessToken', accessToken);
    restClient.defaults.headers.common.authorization = `Token ${accessToken}`;
  }

  public async resetPassword(email: string): Promise<void> {
    await restClient('/api/reset_password/', {
      method: 'POST',
      data: { email },
    });
  }

  public async setNewPassword(password: string): Promise<void> {
    // TODO: Handle the endpoint.
    console.log('password: ', password);
  }
}

// export const authService = new AuthService(restClient);
export { AuthService };
