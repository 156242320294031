import { createContext, useContext, useState } from 'react';
import PopupModal, { PopupModalProps } from 'components/PopupModal';

const PopupContext = createContext<(popupProps: PopupModalProps) => void>(() => {});

type PopupProviderProps = {
  children: React.ReactNode;
};

export const PopupProvider = ({ children }: PopupProviderProps) => {
  const [popup, risePopup] = useState<PopupModalProps>();

  return (
    <PopupContext.Provider value={risePopup}>
      {children}
      {popup && <PopupModal {...popup} />}
    </PopupContext.Provider>
  );
};

const usePopup = () => useContext(PopupContext);

export default usePopup;
