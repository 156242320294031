import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { GraphsAvgData, Timezone } from 'backendTypes';

class GraphsService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getGraphsWeeklyData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/daily-average/', {
      method: 'GET',
    });
    return data;
  }

  async getGraphsMonthlyData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/daily-average/', {
      method: 'GET',
      params: {
        days: 30,
      },
    });
    return data;
  }

  async getGraphsSixMonthsData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/weekly-average/', {
      method: 'GET',
      params: {
        weeks: 24,
      },
    });
    return data;
  }

  async getGraphsLastYearData(): Promise<GraphsAvgData[]> {
    const { data } = await restClient('/api/graphs/weights/monthly-average/', {
      method: 'GET',
    });
    return data;
  }

  async getTimezone(): Promise<Timezone> {
    const { data } = await restClient('/api/profile/timezone/', {
      method: 'GET',
    });
    return data;
  }

  async setTimezone(timezone: string): Promise<Timezone> {
    const { data } = await restClient('/api/profile/timezone/', {
      method: 'PATCH',
      data: { timezone },
    });
    return data;
  }
}

export { GraphsService };
