import { Suspense } from 'react';
import AppLoader from 'components/AppLoader';

interface EnhancedRouteProps {
  layout: React.ComponentType<{ children: React.ReactNode }>;
  content: React.ComponentType;
  layoutProps?: Object;
}

const EnhancedRoute = ({ layout: Layout, content: Content, layoutProps }: EnhancedRouteProps) => {
  return (
    <Suspense fallback={<AppLoader />}>
      <Layout {...layoutProps}>
        <Content />
      </Layout>
    </Suspense>
  );
};

export default EnhancedRoute;
