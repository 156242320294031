import restClient from 'api/restClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AssessmentAnswer, AssessmentData, AssessmentQuestion } from 'backendTypes';

class AssessmentService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getAssessment(): Promise<AssessmentData> {
    const { data } = await restClient(`/api/profile/intro_assessment/?new_version=True`, {
      method: 'GET',
    });
    return data;
  }

  async getAssessmentQuestion(questionId: number): Promise<AssessmentQuestion> {
    const { data } = await restClient(`api/profile/intro_assessment/question/${questionId}/`, {
      method: 'GET',
    });
    return data;
  }

  async postAssessmentAnswer(data: AssessmentAnswer): Promise<AxiosResponse> {
    const resp = await restClient('/api/assessment/answer/', {
      method: 'POST',
      data,
    });
    return resp;
  }

  async finishAssessment(): Promise<AxiosResponse> {
    const data = { completed: true };
    const resp = await restClient('/api/profile/intro_assessment/', {
      method: 'PATCH',
      data,
    });
    return resp;
  }
}

export { AssessmentService };
