import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { Pursuit, Pursuits } from 'backendTypes';

class PursuitsService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getPursuits(): Promise<Pursuits> {
    const { data } = await restClient('/api/pursuits/', { method: 'GET' });
    return data;
  }

  async getActivePursuit(): Promise<Pursuit> {
    const { data } = await restClient('/api/active_pursuit/', {
      method: 'GET',
      params: {
        ignoreRecentAssessment: 1,
      },
    });
    return data;
  }

  async pickPursuit(pursuitId: number): Promise<void> {
    const { data } = await restClient('/api/pursuits/', {
      method: 'POST',
      data: { pursuitId },
    });
    return data;
  }

  async chooseNextPursuit(): Promise<void> {
    const { data } = await restClient('/api/pursuits/', { method: 'POST' });
    return data;
  }

  async abandonPursuit(): Promise<void> {
    const { data } = await restClient('/api/active_pursuit/', {
      method: 'POST',
      data: { abandon: true },
    });
    return data;
  }
}

export { PursuitsService };
