import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { formatPhoneCode, phoneToString } from 'utils/parsePhone';
import {
  OrderScaleData,
  OrderScaleResponse,
  OrderScaleStatus,
  ProfileNotificationAction,
  ProfileNotifications,
} from 'backendTypes';

class ScaleService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getUserDataForOrderScale(): Promise<OrderScaleData> {
    const { data } = await restClient('/bodytrace/order-scale/', {
      method: 'GET',
    });
    return data;
  }

  async validateOrderScale(userData: OrderScaleData): Promise<OrderScaleData> {
    const { data } = await restClient('/bodytrace/order-scale/', {
      method: 'POST',
      data: {
        ...userData,
        phoneCell: phoneToString(userData.phoneCell),
        phoneCode: formatPhoneCode(userData.phoneCell),
        validate: true,
      },
    });
    return data;
  }

  async orderScale(userData: OrderScaleData): Promise<OrderScaleResponse> {
    const { data } = await restClient('/bodytrace/order-scale/', {
      method: 'POST',
      data: { ...userData },
    });
    return data;
  }

  async checkOrderScaleStatus(): Promise<OrderScaleStatus> {
    const { data } = await restClient('/bodytrace/check-order/', {
      method: 'GET',
    });
    return data;
  }

  // Profile Notifications are the notifications that are shown after user steps on the scale
  async getProfileNotifications(): Promise<ProfileNotifications> {
    const { data } = await restClient('/api/profile/notifications/', {
      method: 'GET',
    });
    return data;
  }

  async updateProfileNotifications(
    profileNotificationAction: ProfileNotificationAction
  ): Promise<void> {
    const { data } = await restClient('/api/profile/notifications/mass-action/', {
      method: 'PUT',
      data: profileNotificationAction,
    });
    return data;
  }
}

export { ScaleService };
