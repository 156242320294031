import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { formatPhoneCode, parsePhone, phoneToString } from 'utils/parsePhone';
import resizeImage from 'utils/resizeImage';
import { User } from 'backendTypes';

class UserService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getCurrentUser(): Promise<User | null> {
    if (window.localStorage.getItem('auth:accessToken')) {
      const response = await restClient('/api/profile/');

      if (response?.data) {
        const { data } = response;

        return {
          ...data,
          phoneCell: parsePhone(data.phoneCell),
          workPhone: parsePhone(data.workPhone),
          hasAcceptedPolicy: data.isStaff || data.hasAcceptedPolicy,
        };
      }
      return null;
    } else {
      return null;
    }
  }

  async editCurrentUser(userData: User): Promise<User> {
    const { data } = await restClient(`/api/profile/`, {
      method: 'PATCH',
      data: {
        ...userData,
        phoneCell: phoneToString(userData.phoneCell),
        phoneCode: formatPhoneCode(userData.phoneCell),
        workPhone: phoneToString(userData.workPhone),
        workPhoneCode: formatPhoneCode(userData.workPhone),
      },
    });
    return data;
  }

  async getUser(eligibilityId: string): Promise<User> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/`, {
      method: 'GET',
    });
    return {
      ...data,
      phoneCell: parsePhone(data.phoneCell),
      workPhone: parsePhone(data.workPhone),
    };
  }

  async editUser(userData: User, eligibilityId: string): Promise<User> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/`, {
      method: 'PATCH',
      data: {
        ...userData,
        phoneCell: phoneToString(userData.phoneCell),
        phoneCode: formatPhoneCode(userData.phoneCell),
        workPhone: phoneToString(userData.workPhone),
        workPhoneCode: formatPhoneCode(userData.workPhone),
      },
    });
    return data;
  }

  async updateUserAvatar(eligibilityId: string, file: File): Promise<string> {
    const image = await resizeImage(file);
    const { data } = await restClient('/api/profile/change_avatar/', {
      method: 'POST',
      data: { image, eligibilityId },
    });
    return data.image;
  }

  async updateCurrentUserAvatar(file: File): Promise<string> {
    const image = await resizeImage(file);
    const { data } = await restClient('/api/profile/change_avatar/', {
      method: 'POST',
      data: { image },
    });
    return data.image;
  }

  async agreePolicy(): Promise<void> {
    const { data } = await restClient('/api/profile/policy/', { method: 'POST' });
    return data;
  }
}

export { UserService };
