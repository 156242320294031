import styled from '@emotion/styled';
import { Spin } from 'antd';

const AppLoader = () => (
  <Wrapper>
    <Spin size="large" />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AppLoader;
