import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import prepareBiometricPostRequests from 'utils/prepareBiometricPostRequest';
import { BiometricData, BiometricInputProps, Measurement } from 'backendTypes';

class BiometricService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  public async getBiometricData(eligibilityId: string): Promise<BiometricData> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/measurements/`, {
      method: 'GET',
    });
    return data;
  }

  public async addBiometric(
    values: BiometricInputProps,
    eligibilityId: string,
    initialValues: BiometricData,
    measurement: Measurement
  ): Promise<void> {
    // prepareBiometricPostRequests returns an array of filtered request payloads.
    const postRequests = prepareBiometricPostRequests(
      values,
      initialValues,
      eligibilityId,
      measurement
    );

    for (let index = 0; index < postRequests.length; index++) {
      const payload = postRequests[index];
      await restClient(`/api/measure/`, {
        method: 'POST',
        data: payload,
      });
    }
  }
}

export { BiometricService };
