import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { BackendIcon } from 'backendTypes';

class IconService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getIcon(iconName: string): Promise<BackendIcon> {
    const { data } = await restClient(`/api/icon/${iconName}/`, { method: 'GET' });
    return data;
  }
}

export { IconService };
