import restClient from 'api/restClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { phoneToString } from 'utils/parsePhone';
import { AccountCreationData, RegistrationInfo } from 'backendTypes';

class RegistrationService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async validateRegistrationCode(registrationCode: string): Promise<RegistrationInfo> {
    const { data } = await restClient('/api/registration/eligibility-member/', {
      method: 'GET',
      params: { registrationCode },
    });
    return data;
  }

  async findMatchingProfile(criteria?: RegistrationInfo): Promise<RegistrationInfo> {
    const { data } = await restClient('/api/registration/eligibility-member/', {
      method: 'GET',
      params: criteria,
    });
    return data;
  }

  async createUserAccount(creationData: AccountCreationData): Promise<AccountCreationData> {
    const { phoneNumber, ...rest } = creationData;
    const parsedPhoneNumber = phoneToString(creationData.phoneNumber);
    const { data } = await restClient('/api/registration/user/', {
      method: 'POST',
      data: {
        ...rest,
        ...(parsedPhoneNumber ? { phoneNumber: parsedPhoneNumber } : {}),
      },
    });
    return data;
  }

  async createEligibilityMemberAccount(
    creationData: AccountCreationData
  ): Promise<AccountCreationData> {
    const { data } = await restClient('/api/registration/create-account/', {
      method: 'POST',
      data: creationData,
    });
    return data;
  }

  async resendVerificationEmail(email: string): Promise<AxiosResponse> {
    const resp = await restClient('/api/registration/email/verification-mail/', {
      method: 'POST',
      data: { email },
    });
    return resp;
  }

  async verifyUser(registrationCode: string): Promise<AxiosResponse> {
    const resp = await restClient('/api/registration/email/verification-token/', {
      method: 'GET',
      params: { registrationCode },
    });
    return resp;
  }
}

export { RegistrationService };
