import restClient from 'api/restClient';
import { AxiosInstance } from 'axios';
import { Device, ValidicProfile } from 'backendTypes';

class DevicesService {
  constructor(public readonly httpRequest: AxiosInstance) {}

  async getValidicProfile(): Promise<ValidicProfile> {
    const { data } = await restClient('/api/profile/validic/user', { method: 'GET' });
    return data;
  }

  async connectMyDevice(): Promise<{ url: string }> {
    const { data } = await restClient('/api/profile/devices/connect/', { method: 'GET' });
    return data;
  }

  async getMyDevices(): Promise<Device[]> {
    const { data } = await restClient('/api/profile/devices/', { method: 'GET' });
    return data;
  }

  async getUserDevices(eligibilityId: string): Promise<Device[]> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/devices/`, {
      method: 'GET',
    });
    return data;
  }

  async disconnectMyDevice(deviceId: number): Promise<void> {
    const { data } = await restClient(`/api/profile/devices/`, {
      method: 'PUT',
      data: { deviceId, actionType: 'disconnect' },
    });
    return data;
  }

  async disconnectUserDevice(deviceId: number, eligibilityId: string): Promise<void> {
    const { data } = await restClient(`/api/profile/${eligibilityId}/devices/`, {
      method: 'PUT',
      data: { deviceId, actionType: 'disconnect' },
    });
    return data;
  }
}

export { DevicesService };
